<section id="breadcrumbs" class="breadcrumbs">
    <div class="container">

        <br>
        <div class="d-flex justify-content-between align-items-center">
            <h2 class="text" style="font-family: Georgia, serif;" translate>products.title</h2>
        </div>

    </div>
</section><!-- End Breadcrumbs -->

<div class="container" id="picturesGallery">
    <div class="row no-gutters">
        <div *ngFor="let image of images; let i = index" class="col-lg-3 col-md-4">
            <div class="image-gallery">
                <a style="cursor: pointer;" data-toggle="modal" data-target="#pictures" (click)="setParams(i+1)">
                    <img src="{{image.img}}" alt="" class="img-fluid" style="border-radius: 8px;" />
                </a>
            </div>
        </div>
    </div>
    <br>
</div>

<div class="modal fade bd-example-modal-lg" id="pictures">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title">Tasić enterijeri</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li *ngFor="let image of images; let i = index" attr.data-slide-to="{{i}}"
                            [class]="i+1 === picNumber ?'active':''">
                        </li>
                    </ol>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img class="d-block w-100" src="../../assets/images/{{category}}/{{picNumber}}.jpg" />
                        </div>
                    </div>
                    <a class="carousel-control-prev" (click)="goPrevious()" role="button">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" (click)="goNext()" role="button">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>