<footer style="background-color: #292929; color: white;">
    <div class="container pt-5">
        <div class="row py-4">
            <div class="col-lg-4 col-md-6 mb-4 mb-lg-0" style="padding-right: 5%;"><img
                    src="../../assets/glavniLogo.png" alt="" width="70%" class="mb-3">
                <p class="font-italic" translate>footer.motivation_message</p>
                <ul class="list-inline mt-4">
                    <li class="list-inline-item"><a
                            href="https://www.facebook.com/Tasi%C4%87-Enterijeri-1061640630607138/" target="_blank"
                            title="facebook"><i style="font-size: 30px;" class="fa fa-facebook-square"></i></a>
                    </li>
                    <li class="list-inline-item"><a href="https://www.instagram.com/tasic_enterijeri/" target="_blank"
                            title="instagram"><i style="font-size: 30px;" class="fa fa-instagram"></i></a>
                </ul>
            </div>
            <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
                <h6 class="text-uppercase font-weight-bold mb-4" translate>footer.contact</h6>
                <ul class="list-unstyled mb-0">
                    <li class="mb-2">
                        <p class="text-muted">Predrag Tasić: +381642299255 </p>
                    </li>
                    <li class="mb-2">
                        <p class="text-muted">Nenad Tasić: +381642615353</p>
                    </li>
                    <li class="mb-2">
                        <p class="text-muted">Email: tasicenterijeri@gmail.com</p>
                    </li>
                    <li class="mb-2">
                        <p class="text-muted" translate>footer.work_hours</p>
                    </li>
                </ul>
            </div>

            <div class="col-lg-4 col-md-6 mb-lg-0">
                <h6 class="text-uppercase font-weight-bold mb-4" translate>footer.location</h6>
                <div style="width: 100%"><iframe width="100%" height="100%" frameborder="0" scrolling="no"
                        marginheight="0" marginwidth="0"
                        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Tasic%20Enterijeri,%20E761,%20Drijetanj,%20Serbia+(Tasic%20enterijeri)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                </div>
            </div>
        </div>
    </div>

</footer>