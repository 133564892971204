<section id="breadcrumbs" class="breadcrumbs">
    <div class="container">
        <br>
        <div class="d-flex justify-content-between align-items-center">
            <h2 class="text" style="font-family: Georgia, serif;" translate>products.title</h2>
        </div>

    </div>
</section><!-- End Breadcrumbs -->

<!-- ======= Blog Section ======= -->
<section id="blog" class="blog">
    <div class="container">

        <div class="row">

            <div class="col-lg-4  col-md-6 d-flex align-items-stretch">
                <article class="entry card border-danger mb-3">

                    <div class="entry-img">
                        <img src="../../assets/images/cards/kuhinja.jpg" alt="" class="img-fluid">
                    </div>

                    <h2 class="entry-title">
                        <a translate>products.kitchen_title</a>
                    </h2>


                    <div class="entry-content">
                        <p translate>
                            products.kitchen_text_1
                            <br>
                            products.kitchen_text_2

                        </p>
                        <div class="view-gallery">
                            <a [routerLink]="['/gallery/0']" (click)="scrollToTop()" translate>products.gallery</a>
                        </div>
                    </div>

                </article><!-- End blog entry -->
            </div>

            <div class="col-lg-4  col-md-6 d-flex align-items-stretch">
                <article class="entry card border-danger mb-3">

                    <div class="entry-img">
                        <img src="../../assets/images/cards/poslovniprostor.jpg" alt="" class="img-fluid">
                    </div>

                    <h2 class="entry-title">
                        <a translate>products.workspace_title</a>
                    </h2>

                    <div class="entry-content">
                        <p translate>
                            products.workspace_text
                        </p>
                        <div class="view-gallery">
                            <a [routerLink]="['/gallery/4']" (click)="scrollToTop()" translate>products.gallery</a>
                        </div>
                    </div>

                </article><!-- End blog entry -->
            </div>

            <div class="col-lg-4  col-md-6 d-flex align-items-stretch">
                <article class="entry card border-danger mb-3">

                    <div class="entry-img">
                        <img src="../../assets/images/cards/plakari.jpg" alt="" class="img-fluid">
                    </div>

                    <h2 class="entry-title">
                        <a translate>products.wardrobes_title</a>
                    </h2>



                    <div class="entry-content">
                        <p translate>
                            products.wardrobes_text
                        </p>
                        <div class="view-gallery">
                            <a [routerLink]="['/gallery/1']" (click)="scrollToTop()" translate>products.gallery</a>
                        </div>
                    </div>

                </article><!-- End blog entry -->
            </div>

            <div class="col-lg-4  col-md-6 d-flex align-items-stretch">
                <article class="entry card border-danger mb-3">

                    <div class="entry-img">
                        <img src="../../assets/images/cards/spavacasoba.jpg" alt="" class="img-fluid">
                    </div>

                    <h2 class="entry-title">
                        <a translate>products.bedrooms_title</a>
                    </h2>



                    <div class="entry-content">
                        <p translate>
                            products.bedrooms_text
                        </p>
                        <div class="view-gallery">
                            <a [routerLink]="['/gallery/2']" (click)="scrollToTop()" translate>products.gallery</a>
                        </div>
                    </div>

                </article><!-- End blog entry -->
            </div>

            <div class="col-lg-4  col-md-6 d-flex align-items-stretch">
                <article class="entry card border-danger mb-3">

                    <div class="entry-img">
                        <img src="../../assets/images/cards/boravak.jpg" alt="" class="img-fluid">
                    </div>

                    <h2 class="entry-title">
                        <a translate>products.living_room_title</a>
                    </h2>

                    <div class="entry-content">
                        <p translate>
                            products.living_room_text
                        <div class="view-gallery">
                            <a [routerLink]="['/gallery/3']" (click)="scrollToTop()" translate>products.gallery</a>
                        </div>
                    </div>

                </article><!-- End blog entry -->
            </div>

            <div class="col-lg-4  col-md-6 d-flex align-items-stretch">
                <article class="entry card border-danger mb-3">

                    <div class="entry-img">
                        <img src="../../assets/images/cards/vrata.jpg" alt="" class="img-fluid">
                    </div>

                    <h2 class="entry-title">
                        <a translate>products.doors_title</a>
                    </h2>



                    <div class="entry-content">
                        <p translate>
                            products.doors_text
                        </p>
                        <div class="view-gallery">
                            <a [routerLink]="['/gallery/5']" (click)="scrollToTop()" translate>products.gallery</a>
                        </div>
                    </div>

                </article><!-- End blog entry -->
            </div>

        </div>

    </div>
</section><!-- End Blog Section -->