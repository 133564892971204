import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageServiceService {

  private images: { img: string; id: number; }[];

  constructor() {
    this.images = [
      { img: "../../assets/images/0/1.jpg", id: 0 },
      { img: "../../assets/images/0/2.jpg", id: 0 },
      { img: "../../assets/images/0/3.jpg", id: 0 },
      { img: "../../assets/images/0/4.jpg", id: 0 },
      { img: "../../assets/images/0/5.jpg", id: 0 },
      { img: "../../assets/images/0/6.jpg", id: 0 },
      { img: "../../assets/images/0/7.jpg", id: 0 },
      { img: "../../assets/images/0/8.jpg", id: 0 },
      { img: "../../assets/images/0/9.jpg", id: 0 },
      { img: "../../assets/images/0/10.jpg", id: 0 },
      { img: "../../assets/images/0/11.jpg", id: 0 },
      { img: "../../assets/images/0/12.jpg", id: 0 },
      { img: "../../assets/images/0/13.jpg", id: 0 },
      { img: "../../assets/images/0/14.jpg", id: 0 },
      { img: "../../assets/images/0/15.jpg", id: 0 },
      { img: "../../assets/images/0/16.jpg", id: 0 },
      { img: "../../assets/images/0/17.jpg", id: 0 },
      { img: "../../assets/images/0/18.jpg", id: 0 },
      { img: "../../assets/images/0/19.jpg", id: 0 },
      { img: "../../assets/images/0/20.jpg", id: 0 },
      { img: "../../assets/images/0/21.jpg", id: 0 },
      { img: "../../assets/images/0/22.jpg", id: 0 },
      { img: "../../assets/images/0/23.jpg", id: 0 },
      { img: "../../assets/images/0/24.jpg", id: 0 },
      { img: "../../assets/images/0/25.jpg", id: 0 },
      { img: "../../assets/images/0/26.jpg", id: 0 },
      { img: "../../assets/images/0/27.jpg", id: 0 },
      { img: "../../assets/images/0/28.jpg", id: 0 },
      { img: "../../assets/images/0/29.jpg", id: 0 },
      { img: "../../assets/images/0/30.jpg", id: 0 },
      { img: "../../assets/images/1/1.jpg", id: 1 },
      { img: "../../assets/images/1/2.jpg", id: 1 },
      { img: "../../assets/images/1/3.jpg", id: 1 },
      { img: "../../assets/images/1/4.jpg", id: 1 },
      { img: "../../assets/images/1/5.jpg", id: 1 },
      { img: "../../assets/images/1/6.jpg", id: 1 },
      { img: "../../assets/images/2/1.jpg", id: 2 },
      { img: "../../assets/images/2/2.jpg", id: 2 },
      { img: "../../assets/images/2/3.jpg", id: 2 },
      { img: "../../assets/images/2/4.jpg", id: 2 },
      { img: "../../assets/images/2/5.jpg", id: 2 },
      { img: "../../assets/images/2/6.jpg", id: 2 },
      { img: "../../assets/images/3/1.jpg", id: 3 },
      { img: "../../assets/images/3/2.jpg", id: 3 },
      { img: "../../assets/images/3/3.jpg", id: 3 },
      { img: "../../assets/images/3/4.jpg", id: 3 },
      { img: "../../assets/images/3/5.jpg", id: 3 },
      { img: "../../assets/images/3/6.jpg", id: 3 },
      { img: "../../assets/images/3/7.jpg", id: 3 },
      { img: "../../assets/images/3/8.jpg", id: 3 },
      { img: "../../assets/images/3/9.jpg", id: 3 },
      { img: "../../assets/images/3/10.jpg", id: 3 },
      { img: "../../assets/images/3/11.jpg", id: 3 },
      { img: "../../assets/images/3/12.jpg", id: 3 },
      { img: "../../assets/images/3/13.jpg", id: 3 },
      { img: "../../assets/images/3/14.jpg", id: 3 },
      { img: "../../assets/images/3/15.jpg", id: 3 },
      { img: "../../assets/images/3/16.jpg", id: 3 },
      { img: "../../assets/images/3/17.jpg", id: 3 },
      { img: "../../assets/images/3/18.jpg", id: 3 },
      { img: "../../assets/images/3/19.jpg", id: 3 },
      { img: "../../assets/images/3/20.jpg", id: 3 },
      { img: "../../assets/images/3/21.jpg", id: 3 },
      { img: "../../assets/images/3/22.jpg", id: 3 },
      { img: "../../assets/images/3/23.jpg", id: 3 },
      { img: "../../assets/images/3/24.jpg", id: 3 },
      { img: "../../assets/images/3/25.jpg", id: 3 },
      { img: "../../assets/images/4/1.jpg", id: 4 },
      { img: "../../assets/images/4/2.jpg", id: 4 },
      { img: "../../assets/images/4/3.jpg", id: 4 },
      { img: "../../assets/images/4/4.jpg", id: 4 },
      { img: "../../assets/images/4/5.jpg", id: 4 },
      { img: "../../assets/images/4/6.jpg", id: 4 },
      { img: "../../assets/images/4/7.jpg", id: 4 },
      { img: "../../assets/images/4/8.jpg", id: 4 },
      { img: "../../assets/images/4/9.jpg", id: 4 },
      { img: "../../assets/images/4/10.jpg", id: 4 },
      { img: "../../assets/images/4/11.jpg", id: 4 },
      { img: "../../assets/images/4/12.jpg", id: 4 },
      { img: "../../assets/images/4/13.jpg", id: 4 },
      { img: "../../assets/images/4/14.jpg", id: 4 },
      { img: "../../assets/images/4/15.jpg", id: 4 },
      { img: "../../assets/images/4/16.jpg", id: 4 },
      { img: "../../assets/images/4/17.jpg", id: 4 },
      { img: "../../assets/images/4/18.jpg", id: 4 },
      { img: "../../assets/images/4/19.jpg", id: 4 },
      { img: "../../assets/images/5/1.jpg", id: 5 },
      { img: "../../assets/images/5/2.jpg", id: 5 },
      { img: "../../assets/images/5/3.jpg", id: 5 },
      { img: "../../assets/images/5/4.jpg", id: 5 },
      { img: "../../assets/images/5/5.jpg", id: 5 },
      { img: "../../assets/images/6/1.jpg", id: 6 },
      { img: "../../assets/images/6/2.jpg", id: 6 },
      { img: "../../assets/images/6/3.jpg", id: 6 },
      { img: "../../assets/images/6/4.jpg", id: 6 },
      { img: "../../assets/images/6/5.jpg", id: 6 },
      { img: "../../assets/images/6/6.jpg", id: 6 },
      { img: "../../assets/images/6/7.jpg", id: 6 },
      { img: "../../assets/images/6/8.jpg", id: 6 },
      { img: "../../assets/images/6/9.jpg", id: 6 },
      { img: "../../assets/images/6/10.jpg", id: 6 },
      { img: "../../assets/images/6/11.jpg", id: 6 },
      { img: "../../assets/images/6/12.jpg", id: 6 },
      { img: "../../assets/images/6/13.jpg", id: 6 },
      { img: "../../assets/images/6/14.jpg", id: 6 },
      { img: "../../assets/images/6/15.jpg", id: 6 },
      { img: "../../assets/images/6/16.jpg", id: 6 },
      { img: "../../assets/images/6/17.jpg", id: 6 },
      { img: "../../assets/images/6/18.jpg", id: 6 },
      { img: "../../assets/images/6/19.jpg", id: 6 },
      { img: "../../assets/images/6/20.jpg", id: 6 },
      { img: "../../assets/images/6/21.jpg", id: 6 },
    ];
  }

  public getAllImages() {
    return this.images;
  }

  public getImagesByCategory(id: number) {
    return this.images.filter(x => x.id === id);
  }
}
