import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  constructor(private titleService: Title, private meta: Meta) { 
    this.titleService.setTitle('Tasic Enterijeri');
    this.meta.addTag({ name: 'description', content: 'Život je ono što ti se dešava dok ti praviš planove, zato planiraj najbolje uz Tasić enterijere.'});
    this.meta.addTag({ name: 'keywords' , content: 'tasic enterijeri, Tasic Enterijeri, Tasic enterijeri, tasic, enterijeri, namestaj po meri, namestaj'}); 
  }
  
  public scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  ngOnInit(): void {
  }

}
