import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataServiceService } from '../services/data-service.service';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit, OnDestroy {

  language: boolean;
  subscription: Subscription;

  constructor(private data: DataServiceService,private titleService: Title, private meta: Meta) { 
    this.titleService.setTitle('Tasic Enterijeri');
    this.meta.addTag({ name: 'description', content: 'Život je ono što ti se dešava dok ti praviš planove, zato planiraj najbolje uz Tasić enterijere.'});
    this.meta.addTag({ name: 'keywords' , content: 'tasic enterijeri, Tasic Enterijeri, Tasic enterijeri, tasic, enterijeri, namestaj po meri, namestaj'}); 
  }

  public scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  ngOnInit(): void {
    this.subscription = this.data.currentLanguage.subscribe(language => this.language = language)
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
