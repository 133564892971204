<head>
  <meta charset="utf-8">
  <title>Tasic Enterijeri</title>
  <meta name="description" content="Tasić Enterijeri su firma koja se bavi proizvodnjom nameštaja po meri. Već više od 10 godina, kompanija Tasić Enterijeri nudi odličan kvalitet i uslugu.">
  <meta name="keywords" content="Tasic, tasic, enterijeri, namestaj, plakari, kuhinje, izrada">
  <base href=/>
  <meta name="viewport" content="width=device-width,initial-scale=1">
</head>
<div>
  <div class="container-fluid px-0">
    <div id="bootstrapCarousel" class="carousel slide" data-ride="carousel">
      <!-- Slide Indicators -->
      <ul class="carousel-indicators">
        <li data-target="#bootstrapCarousel" data-slide-to="0" class="active"></li>
        <li data-target="#bootstrapCarousel" data-slide-to="1"></li>
        <li data-target="#bootstrapCarousel" data-slide-to="2"></li>
      </ul>

      <!-- The slides to show -->
      <div class="carousel-inner" role="listbox">
        <div class="carousel-item active">
          <img src="../../assets/images/Carousel/2.jpg" class="d-block w-100" alt="kuhinja-1">
        </div>
        <div class="carousel-item">
          <img src="../../assets/images/Carousel/3.jpg" class="d-block w-100" alt="kuhinja-2">
        </div>
        <div class="carousel-item">
          <img src="../../assets/images/Carousel/1.jpg" class="d-block w-100" alt="plakari">
        </div>
      </div>

      <!--  Controls (Left and right) -->
      <a class="carousel-control-prev" href="#bootstrapCarousel" data-slide="prev">
        <span class="carousel-control-prev-icon"></span>
      </a>
      <a class="carousel-control-next" href="#bootstrapCarousel" data-slide="next">
        <span class="carousel-control-next-icon"></span>
      </a>
    </div>
  </div>

  <div *ngIf="language; else english" class="container-fluid" style="padding-top: 4%;">
    <div class="row">
      <div class="col-sm-4"><img [routerLink]="['/gallery/0']" (click)="scrollToTop()" src="../../assets/images/pocetnalinkslike/kuhinje.jpg"
          class="d-block w-100" alt="kuhinja">
      </div>
      <div class="col-sm-4"><img [routerLink]="['/gallery/1']" (click)="scrollToTop()" src="../../assets/images/pocetnalinkslike/plakari.jpg"
          class="d-block w-100" alt="plakari"></div>
      <div class="col-sm-4"><img [routerLink]="['/gallery/2']" (click)="scrollToTop()"
          src="../../assets/images/pocetnalinkslike/spavacasoba.jpg" class="d-block w-100"></div>
      <div class="w-100" alt="spavaca-soba"></div>
      <div class="col-sm-4"><img [routerLink]="['/gallery/3']" (click)="scrollToTop()" src="../../assets/images/pocetnalinkslike/boravak.jpg"
          class="d-block w-100" alt="dnevni-boravak"></div>
      <div class="col-sm-4"><img [routerLink]="['/gallery/4']" (click)="scrollToTop()"
          src="../../assets/images/pocetnalinkslike/poslovniprostori.jpg" class="d-block w-100" alt="poslovni-prostor"></div>
      <div class="col-sm-4"><img [routerLink]="['/gallery/5']" (click)="scrollToTop()" src="../../assets/images/pocetnalinkslike/vrata.jpg"
          class="d-block w-100" alt="vrata"></div>
    </div>
  </div>

  <ng-template #english>
    <div class="container-fluid" style="padding-top: 4%;">
      <div class="row">
        <div class="col-sm-4"><img [routerLink]="['/gallery/0']" (click)="scrollToTop()" src="../../assets/images/pocetnalinkslike/kitchens.jpg"
            class="d-block w-100">
        </div>
        <div class="col-sm-4"><img [routerLink]="['/gallery/1']" (click)="scrollToTop()"
            src="../../assets/images/pocetnalinkslike/wardrobes.jpg" class="d-block w-100"></div>
        <div class="col-sm-4"><img [routerLink]="['/gallery/2']" (click)="scrollToTop()" src="../../assets/images/pocetnalinkslike/bedrooms.jpg"
            class="d-block w-100"></div>
        <div class="w-100"></div>
        <div class="col-sm-4"><img [routerLink]="['/gallery/3']" (click)="scrollToTop()"
            src="../../assets/images/pocetnalinkslike/living_room.jpg" class="d-block w-100"></div>
        <div class="col-sm-4"><img [routerLink]="['/gallery/4']" (click)="scrollToTop()"
            src="../../assets/images/pocetnalinkslike/workspaces.jpg" class="d-block w-100"></div>
        <div class="col-sm-4"><img [routerLink]="['/gallery/5']" (click)="scrollToTop()" src="../../assets/images/pocetnalinkslike/doors.jpg"
            class="d-block w-100"></div>
      </div>
    </div>
  </ng-template>


  <div class="card text-center">
    <div class="embed-responsive embed-responsive-21by9">
      <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/8tB8YKQ_dnA" allowfullscreen></iframe>
    </div>
  </div>
</div>